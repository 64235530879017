.mfa-device {
    padding: 5px 20px 5px 10px;
    border-bottom: 1px #EEE solid;
    text-align: left;
    display:flex;
}
.mfa-device-icon {
    padding: 5px 10px;
    font-size: 5em !important;
    opacity: 0.15;
}
.mfa-device-icon-active {
    opacity: 0.25;
}
.mfa-device-inner {
    flex-grow: 1;
}
.mfa-device-inner > * {
    margin: 7px 10px;
}
.mfa-device:last-child {
    border-bottom: none;
}
.mfa-device-buttons {
    display:flex;
    /* justify-content: flex-end; */
    width: 100%;
}
.mfa-device-buttons div{
    display: block !important;
    margin-right: 10px;
}