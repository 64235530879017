.hint-box {
  background: rgba(255, 216, 0, 0.2);
  border-radius: 5px;
  padding: 10px 16px;
  margin: 10px 0;
  color: #665F38;
}
.code-block {
  padding: 20px !important;
  white-space: pre-wrap;
  background: #f4f4f4 !important;
  border-radius: 4px;
  font-family: Menlo, Consolas, "Andale Mono", "Lucida Console", monospace !important;
  box-sizing: border-box;
}
.code-block.small {
  padding: 4px 8px !important;
  font-size: 0.8em;
}