body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

/* set rem to 16px */
html {
  font-size: 16px;
}
