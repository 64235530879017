.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.os-scroll {
  scrollbar-width: thin; /* Firefox */
}

.os-scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  padding: 10px
}
 
.os-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}
 
.os-scroll::-webkit-scrollbar-thumb {
  background-color: #E0E0E0;
  border-radius: 6px;
  border: 3px solid white;
}